import React, { useState, useRef } from 'react';
import './LoveLetter.css';
import audioFile from './music.mp3';

const flyingPhotos = [
  'https://i.ibb.co/tcqYhJN/2024-08-05-10-53-removebg-preview.png',  // Replace with actual paths
  'https://i.ibb.co/wQPPFLv/2024-08-05-14-31-removebg-preview.png',
  'https://i.ibb.co/n7Fpkh3/2024-09-24-17-55-removebg-preview.png',
];

const LoveLetter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);
  const audioRef = useRef(null);

  const handleOpenLetter = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsFullSize(true);
      if (audioRef.current) {
        audioRef.current.play()
          .then(() => console.log("Playback succeeded"))
          .catch(e => console.error("Playback failed:", e));
      }
    }, 800);
  };

  const handleCloseLetter = () => {
    setIsFullSize(false);
    setTimeout(() => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setIsOpen(false);
    }, 800);
  };

  return (
    <div className={`envelope ${isOpen ? 'open' : ''}`} onClick={!isFullSize ? handleOpenLetter : handleCloseLetter}>
      {/* Happy Birthday text, visible only when closed */}
      {!isOpen && (
        <div className="happy-birthday">
          Happy Birthday ya rouhyy ❤🎉
        </div>
      )}

      <div className="flap"></div>
      <div className="body"></div>
      <div className={`letter ${isFullSize ? 'fullSize' : ''}`}>
        i found a loveee :)),   habibii doudouu mezyentii rouh 9albiii :),<br />
        nshaalh kol aam w enti hayaa bkhir w nshaalh kol aam w ahna maa baadhna nhebek barsha w fou9 me tetsawr w bsh nabka nhebek laamor lkoll ya rouhy entiii
        nshaalh yji nhar w nwaliw fih kol youm fi wjouh baadhna w nshaalh one day we share our home ❤ nhebek nhebek nhebek ya mezyentii ya azyztyyy samahni ala kol haja aamlthelk nshlh mel youm hedha ytsalho barsha hajet khatr nheb nkamel maakk w nshalh nkoun farhtk b small gift hedhii nhebek <br />i loveeee youuuu :)<br />
        always yours,<br />
        @douaa_lahmer
      </div>

      {/* Flying Photos */}
      {isFullSize && flyingPhotos.map((photo, index) => (
        <img 
          key={index} 
          src={photo} 
          alt={`Flying photo ${index + 1}`} 
          className={`flying-photo flying-photo-${index}`} 
        />
      ))}

      <audio ref={audioRef} src={audioFile} onError={(e) => console.error('Audio error:', e.message)} />
    </div>
  );
};

export default LoveLetter;
